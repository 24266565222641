import {
  faEnvelope,
  faLocationDot,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, clientKey } from "../../config/config";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";
const Footer = () => {
  const navigate = useNavigate();
  //arabic language-english
  languageFunc(useContext(langContext));

  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  const linksArr = [
    { title: changedLang?.home, path: '/' },
    { title: changedLang?.about, path: '/about' },
    { title: changedLang?.contact, path: '/contact' },
    { title: changedLang?.termsCond, path: '/terms-condtions' },
    { title: changedLang?.help, path: '/help' },

  ]
  //get services
  const [services, setServices] = useState([]);
  const getServices = async () => {
    axios
      .post(`${BASE_URL}/getservices/`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setServices(res.data.data);
        } else {
          return;
        }
      })
      .catch(() => navigate("/error"));
  };
  useEffect(() => {
    getServices();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <footer className="footer text-lg-start">
        <div className="container text-white">
          <div className="row me-5 pt-5 ">
            {/* usefull lins */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 ">
              <h5 className="footer-topics mb-4">{changedLang.usefullLinks}</h5>
              <ul className="list-unstyled">
                {
                  linksArr?.map((link, index) => (
                    <li key={index} className="mb-1">
                      <Link to={link.path} className="text-white">
                        {link.title}
                      </Link>
                    </li>

                  ))
                }
              </ul>
            </div>
            {/* services  */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 ">
              <h5 className="footer-topics mb-4">{changedLang.services}</h5>
              <ul className="list-unstyled mb-0">
                {services &&
                  services.map((service, i) => (
                    <li key={i} className="mb-1">
                      <a
                        href={`services?param=${service.url}`}
                        className="text-white"
                      >
                        {service.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            {/* for partners  */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 ">
              <h5 className="footer-topics mb-4">{changedLang.forPartners}</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to='/' className="text-white">
                    Register as a professional
                  </Link>
                </li>
              </ul>
            </div>
            {/* get in touch  */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 ">
              <h5 className="footer-topics mb-4">{changedLang.getInTouch}</h5>
              <ul className="list-unstyled">
                <li className="d-flex align-items-center gap-3 mb-1">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span >
                    Building No:3456, Burje Kalifa, Riyadh, 12345, Saudi Arabia
                  </span>
                </li>
                <li className="d-flex align-items-center gap-3 mb-1">
                  <FontAwesomeIcon icon={faPhone} />
                  <span>+966 1 123 1234</span>
                </li>
                <li className="d-flex align-items-center gap-3 mb-1">
                  <FontAwesomeIcon icon={faMobile} />
                  <span>+966 1 123 1234</span>
                </li>
                <li className="d-flex align-items-center gap-3 mb-1">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span>info@unjiz.com</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-3 text-white text-center footer-bottom">
          © 2024 {changedLang.copyRight} :{" "}
          <Link to="/" className="text-white">
            UNJIZ
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
