import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OrderPlaced = () => {
  const navigate = useNavigate();
  const returnHandler = () => {
    navigate("/");
  };
  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);
  return (
    <div className="container">
      <div className="ordered">
        <h1 className="heading">Congratulations.....!</h1>
        <p>Your order is placed successfully...!</p>
        <button className="btn btn-success" onClick={returnHandler}>
          Return <FontAwesomeIcon icon={faHome} />
        </button>
      </div>
    </div>
  );
};

export default OrderPlaced;
