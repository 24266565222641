import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL, clientKey, currency } from "../../config/config";
import axios from "axios";
import cartContext from "../../context/cartContext";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const PackageDetails = () => {
  //getting
  const cart = useContext(cartContext);
  let cartData = cart.cartItems;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let packageURL = searchParams.get("param");
  //get packages
  const [packageDetails, setPackageDetails] = useState([]);

  //laguage id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //arabic language-english
  languageFunc(useContext(langContext));

  const getPackageDetails = async () => {
    axios
      .post(`${BASE_URL}/getpackagedetails/`, {
        clientKey,
        packageURL,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setPackageDetails(res.data.data);
        } else {
          return;
        }
      })
      .catch((e) => navigate("/error"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackageDetails();
    // eslint-disable-next-line
  }, []);

  //checking status
  const checkCartStatus = (packageId) => {
    let cartItems = JSON.parse(localStorage.getItem("cartList"));
    let status = false;
    if (localStorage.getItem("cartList")) {
      for (let i = 0; i < cartItems.length; i++) {
        if (packageId === cartItems[i].package_id) {
          status = true;
          break;
        }
      }
      return status;
    }
  };

  const [selectedLanguage] = useState(
    sessionStorage.getItem("language")
      ? JSON.parse(sessionStorage.getItem("language"))
      : { id: "1", language: "EN", flag: "en" }
  );
  //navigating to cart
  const clickCartHandler = () => {
    navigate("/cart");
  };

  //adding to cart
  const addToCartHandler = (data) => {
    let costType = document.getElementById(
      "DdlCostType_" + data.package_id
    ).value;
    console.log(costType);
    if (localStorage.getItem("cartList")) {
      let cartList = JSON.parse(localStorage.getItem("cartList"));
      for (let i = 0; i < cartList.length; i++) {
        if (cartList[i].package_id === data.package_id) {
          return;
        }
      }
      let cartVal = {
        package_id: data.package_id,
        vendor_id: data.vendor_id,
        package_title: data.title,
        package_cost: costType === "1" ? data.cost : data.hourly_cost,
        cost_type: costType,
      };

      cartList.push(cartVal);
      localStorage.setItem("cartList", JSON.stringify(cartList));
    } else {
      let cartList = [
        {
          package_id: data.package_id,
          vendor_id: data.vendor_id,
          package_title: data.title,
          package_cost: costType === "1" ? data.cost : data.hourly_cost,
          cost_type: costType,
        },
      ];
      localStorage.setItem("cartList", JSON.stringify(cartList));
    }
    window.location.reload();
  };

  //total item counts
  let totalItems = 0;
  for (let i = 1; i <= cartData.length; i++) {
    totalItems = i;
  }

  // chnaging the cost type
  const changeCostType = (data) => {
    document.getElementById("spcost_" + data.package_id).innerHTML =
      document.getElementById("DdlCostType_" + data.package_id).value === "1"
        ? data.cost + "<span class='small-text'>/Package</span>"
        : data.hourly_cost + "<span class='small-text'>/Hr</span>";
  };
  return (
    <div className="container">
      <div className="allpages mb-3">
        <h5 className="sub-heading pkg_dtl">{changedLang.packageDetails}</h5>
      </div>
      <div className="row packages package_scroll pt-5">
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 ">
          {packageDetails?.map((data, i) => (
            <div key={i} className=" package_card bg-light">
              <div className="row mb-3">
                <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <h4 className="sub-heading text-color">{data.title}</h4>
                  <p>{data.desc}</p>
                  {data.cost !== 0 && data.hourly_cost !== 0 ? (
                    <>
                      <div className="pb-3 ">
                        <select
                          className="form-select w-auto"
                          id={"DdlCostType_" + data.package_id}
                          onChange={() => changeCostType(data)}
                        >
                          <option value="1">Per Package</option>
                          <option value="2">Per Hour</option>
                        </select>
                      </div>
                      <h5>
                        <span className="currency">{currency} </span>
                        <span id={"spcost_" + data.package_id}>
                          {data.cost}
                          <span className="small-text">/Package</span>
                        </span>
                      </h5>
                    </>
                  ) : (
                    <>
                      <input
                        type="hidden"
                        id={"DdlCostType_" + data.package_id}
                        value={data.cost !== 0 ? 1 : 2}
                      ></input>
                      <h5>
                        <span className="currency">{currency} </span>
                        {data.cost !== 0 ? data.cost : data.hourly_cost}
                        <span className="small-text">
                          {data.cost !== 0 ? "/Package" : "/Hr"}
                        </span>
                      </h5>
                    </>
                  )}
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <Link to={`/vendor-details?param=${data.vendor_url}`}>
                    <div className="vendor-img">
                      <img src={data.vendor_image} alt="vendor" />
                    </div>
                    <h6 className="text-color">{data.company_name}</h6>
                  </Link>
                  <div className=" cart_btn">
                    {checkCartStatus(data.package_id) ? (
                      <button className="btn added_to_cart">
                        {changedLang.added}
                      </button>
                    ) : (
                      <button
                        className="btn btn-success"
                        onClick={() => addToCartHandler(data)}
                      >
                        {changedLang.add}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
        <div className="col-sm-2 col-md-5 col-lg-3 col-xl-3  cart">
          <div className=" card cart_block bg-light">
            <div style={{ position: "relative" }}>
              <div
                className={`${selectedLanguage.flag === "ar"
                  ? "ar_package_cart-count"
                  : "package_cart-count"
                  }`}
              >
                {" "}
                {totalItems}
              </div>
              <FontAwesomeIcon icon={faCartShopping} className="cart_icon" />
            </div>
            <p
              className={`${!localStorage.getItem("cartList")
                ? "show_cart_title text-center mt-3"
                : "hide_cart_title"
                }`}
            >
              {changedLang.noItems}
            </p>

            <div className="mt-5">
              {localStorage.getItem("cartList") &&
                cartData.map((data, i) => (
                  <div key={i} className="row">
                    <div className="col-sm-12 col-md-12">
                      {data.package_title}
                      <span className="small-text">
                        {data.cost_type === "1" ? "/Package" : "/Hr"}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={`${localStorage.getItem("cartList")
                ? "show_cart_btn text-center btn btn-success mt-3"
                : "hide_cart_title"
                }`}
              onClick={clickCartHandler}
            >
              {changedLang.viewCart}
            </div>
          </div>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faCartShopping}
            onClick={clickCartHandler}
            className={`${selectedLanguage.flag === "ar"
              ? "ar_md_cart_icon"
              : "md_cart_icon"
              }`}
          />
          <span
            className={`${selectedLanguage.flag === "ar"
              ? "ar_md_item_count"
              : "md_item_count"
              }`}
          >
            {totalItems}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
