import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
const Help = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const helpArr = [
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
    {
      que: "Placeholder content for this accordion, which is intended to demonstrate the class?",
      ans: "Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.Placeholder content for this accordion, which is intended to demonstrate the class. This is the first item's accordion body.",
    },
  ];

  return (
    <div className="container allpages">
      <h4 className="heading p-3">This may help you</h4>
      <Accordion>
        {helpArr?.map((data, i) => (
          <Accordion.Item eventKey={i} key={i}>
            <Accordion.Header className="help-accordian">
              <span className="fw-bold">Question : </span>
              <span className="mx-3">{data.que}</span>
            </Accordion.Header>
            <Accordion.Body>
              <span className="fw-bold">Answer: </span>
              <span className="mx-3">{data.ans}</span>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Help;
