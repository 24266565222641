import React, { useEffect, useState } from "react";

import bannerVideo from "../../assets/videos/banner.mp4";
import Testimonials from "../testimonials/Testimonials";

import { Link, useNavigate } from "react-router-dom";
import banner from "../../assets/images/banner.jpg";
import { useContext } from "react";
import langContext from "../../context/langsContext";
import axios from "axios";
import { BASE_URL, clientKey } from "../../config/config";
import { languageFunc, changedLang } from "../../config/language";

const Home = () => {
  const navigate = useNavigate();
  //laguage id
  let languageID = JSON.parse(sessionStorage.getItem("language"));
  languageID = languageID.id;

  //get services
  const [services, setServices] = useState([]);
  const getServices = async () => {
    axios
      .post(`${BASE_URL}/getservices/`, {
        clientKey,
        langID: languageID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setServices(res.data.data);
        } else {
          return;
        }
      })
      .catch(() => navigate("/error"));
  };

  useEffect(() => {
    getServices();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  //arabic language-english
  languageFunc(useContext(langContext));

  return (
    <div className="home">
      <div className="banner">
        <video
          height="50%"
          width="100%"
          controls={true}
          autoPlay={true}
          loop={true}
        >
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
      {/* About */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <img src={banner} alt="banner" className="abt_banner" />
          </div>
          <div className="col-lg-6">
            <h4 className=" heading text-left text-color">{changedLang.aboutPage}</h4>
            <h1 className="sub-heading">{changedLang.aboutPageSubhead}</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <Link to="/about">{changedLang.readMore}</Link>
          </div>
        </div>
      </div>
      {/* Services  */}
      <div className="allpages container">
        <h4 className="heading text-color">{changedLang.service}</h4>
        <h1 className="sub-heading mb-3">{changedLang.serviceSubhead}</h1>
        <div className="row mb-3">
          {services?.map((data, i) => (
            <Link
              key={i}
              to={`services?param=${data.url}`}
              className=" col-sm-6 col-md-4 col-lg-4 col-xl-3"
            >
              <div>
                <div className="card mt-3 cards">
                  <div className="service-banner card-img">
                    <img
                      src={data.banner}
                      alt="banner"
                      className="card-img-top"
                    />
                  </div>
                  <div className="card-body home_service_card ">
                    <h4 className=" text-color">{data.title}</h4>
                    <div className="">
                      <p className="card-text ">{data.description}</p>
                      <p>
                        {" "}
                        <Link to={`services?param=${data.url}`}>
                          {changedLang.readMore}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Testimonials />
    </div>
  );
};

export default Home;
