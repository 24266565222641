import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import cartContext from "../../context/cartContext";
import { useNavigate } from "react-router-dom";
import { currency } from "../../config/config";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const Cart = (props) => {
  const navigate = useNavigate();

  //language english - arabic
  languageFunc(useContext(langContext));

  // cart context
  const cart = useContext(cartContext);
  let cartData = cart.cartItems;

  //calculation total cost to pay
  let totalCost = 0;
  cartData.map((data) => (totalCost += data.package_cost));

  //deleting items from cart
  const removeHandler = (package_id) => {
    let itemsLeft = cartData.filter((item) => item.package_id !== package_id);
    if (itemsLeft.length > 0) {
      localStorage.removeItem("cartList");
      localStorage.setItem("cartList", JSON.stringify(itemsLeft));
    } else {
      localStorage.removeItem("cartList");
    }
    window.location.reload();
  };

  //checkout handler
  const checkoutHandler = () => {
    sessionStorage.getItem("user_id")
      ? navigate("/checkout")
      : props.checkAuth();
  };

  const clickHomeHandler = () => {
    navigate("/");
  };
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  return (
    <div className="container">
      <div className="allpages">
        <div
          className={`${localStorage.getItem("cartList") ? "show_cart row p-5" : "hide_cart"
            }`}
        >
          <div className="carts_body">
            <h3 className=" heading text-color ">{changedLang.cartHead}</h3>
            <div className="card p-3 bg-light">
              <div className="cart_head">
                <h5>{changedLang.title}</h5>
                <h5>{changedLang.amnt}</h5>
              </div>
              {localStorage.getItem("cartList") &&
                cartData.map((data, i) => (
                  <div key={i} className="row p-2 ">
                    <div className="text-color col-8">
                      {data.package_title}
                      <span className="small-text">
                        {data.cost_type === "1" ? "/Package" : "/Hr"}
                      </span>
                    </div>

                    <div className="col-1">
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="text-danger trash"
                        onClick={() => removeHandler(data.package_id)}
                      />
                    </div>
                    <div className=" col-3 text-end">
                      <span className="currency">{currency}</span>{" "}
                      {data.package_cost}
                    </div>
                  </div>
                ))}

              <hr style={{ borderTop: "dotted 1px" }} />

              <div className=" p-2 checkout_p">
                <h5 className="text-color ">{changedLang.totalCost}</h5>
                <div className="">
                  <span className="currency">{currency}</span> {totalCost}
                </div>
              </div>
              <div className=" p-3">
                <button
                  className="btn  btn-primary w-100"
                  onClick={checkoutHandler}
                >
                  {changedLang.checkoutBtn}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* empty cart */}
      <div
        className={`${!localStorage.getItem("cartList")
            ? "show_empty_cart "
            : "hide_empty_cart"
          }`}
      >
        <p>{changedLang.noItems}</p>
        <button className="btn btn-success" onClick={clickHomeHandler}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Cart;
