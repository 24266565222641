import React, { useEffect } from "react";
import banner from "../../assets/images/cleaning.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";

const About = () => {
  // language english - arabic
  languageFunc(useContext(langContext));

  //page scrolling
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid bannerhead">
        <div className="text-center">
          <h1 className="heading text-white">{changedLang?.about}</h1>
          <div className="text-white">
            <Link to="/" className="text-white">
              {changedLang?.home}
            </Link>{" "}
            <span>
              <FontAwesomeIcon icon={faAnglesRight} />
            </span>{" "}
            <span className="text-white">{changedLang?.about}</span>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-lg-6 pt-5">
            <img src={banner} alt="banner" width="100%" height="80%" />
          </div>
          <div className="col-lg-6 p-5">
            <h4 className="heading text-left text-color"> {changedLang?.aboutPage}</h4>
            <h1 className="sub-heading">{changedLang?.aboutPageSubhead}</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
        <div>
          <h4 className="heading text-color">{changedLang?.whyChoose}</h4>
          <h1 className="sub-heading">{changedLang?.ansWhyChoose}</h1>
        </div>
        <div className="row">
          <div className="col-lg-3 mb-3 ">
            <div className="card mt-3  ">
              <div className="card-body aboutCards">
                <h2 className="heading card-title text-white">
                  {changedLang?.trackRecord}
                </h2>
                <p className="card-text text-white">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Natus sint accusamus
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-3 ">
            <div className="card mt-3  ">
              <div className="card-body aboutCards">
                <h2 className="heading card-title text-white">
                  {changedLang?.knowledge}
                </h2>
                <p className="card-text text-white">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Natus sint accusamus
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-3 ">
            <div className="card mt-3  ">
              <div className="card-body aboutCards">
                <h2 className="heading card-title text-white">{changedLang?.exp}</h2>
                <p className="card-text text-white">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Natus sint accusamus
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-3 ">
            <div className="card mt-3  ">
              <div className="card-body aboutCards">
                <h2 className="heading card-title text-white">{changedLang?.flex}</h2>
                <p className="card-text text-white">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Natus sint accusamus
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <h2 className="sub-heading">{changedLang?.trackRecord}- </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
        <div className="mb-5">
          ~<h2 className="sub-heading">{changedLang?.knowledge}- </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
        <div className="mb-5">
          <h2 className="sub-heading">{changedLang?.exp}- </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
        <div className="mb-5">
          <h2 className="sub-heading">{changedLang?.flex}- </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
