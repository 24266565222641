import { faCircleUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BASE_URL, clientKey } from "../../config/config";
import axios from "axios";
import langContext from "../../context/langsContext";
import { languageFunc, changedLang } from "../../config/language";
import {
  checkForBlank,
  validateEmail,
  validateMobile,
} from "../../lib/validation";

const MyAccount = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //arabic language-english
  languageFunc(useContext(langContext));

  //email modal state
  const [emailModal, setEmailModal] = useState(false);
  const handleShowEmail = () => setEmailModal(true);
  const handleCloseEmail = () => setEmailModal(false);

  //email otp validate modal state
  const [emailOtpModal, setEmailOtpModal] = useState(false);
  const handleCloseEmailOtp = () => setEmailOtpModal(false);

  //email changed alert message modal state
  const [emailChangedModal, setemailChangedModal] = useState(false);
  const handleShowEmailChanged = () => setemailChangedModal(true);
  const handleCloseEmailChanged = () => setemailChangedModal(false);

  //mobile number modal state
  const [mobileModal, setMobileModal] = useState(false);
  const handleShowMobile = () => setMobileModal(true);
  const handleCloseMobile = () => setMobileModal(false);

  //mobile otp validate modal state
  const [mobileOtpModal, setMobileOtpModal] = useState(false);
  const handleCloseMobileOtp = () => setMobileOtpModal(false);

  //mobile changed alert message modal state
  const [mobileChangedModal, setMobileChangedModal] = useState(false);
  const handleShowMobileChanged = () => setMobileChangedModal(true);
  const handleCloseMobileChanged = () => setMobileChangedModal(false);

  //Password modal state
  const [passwordModal, setPasswordModal] = useState(false);
  const handleShowPassword = () => setPasswordModal(true);
  const handleClosePassword = () => setPasswordModal(false);

  //password changed alert message modal state
  const [passworChangedModal, setPasswordChangedModal] = useState(false);
  const handleShowPasswordChanged = () => setPasswordChangedModal(true);
  const handleClosePasswordChanged = () => setPasswordChangedModal(false);

  // change password states
  const [oldpwd, setOldpwd] = useState("");
  const [newpwd, setNewpwd] = useState("");
  const [cnewpwd, setCnewpwd] = useState("");

  //my acount details
  const [myAccount, setMyAccount] = useState([]);

  // email states
  const [emailID, setEmailID] = useState("");
  const [emailotp, setEmailotp] = useState("");
  // mobile states
  const [mobnum, setMobnum] = useState("");
  const [mobnumotp, setMobnumotp] = useState("");

  //checking language
  const [selectedLanguage] = useState(
    sessionStorage.getItem("language")
      ? JSON.parse(sessionStorage.getItem("language"))
      : { id: "1", language: "EN", flag: "en" }
  );

  // getting  my acount details
  const getMyAccountDetails = () => {
    axios
      .post(`${BASE_URL}/getcustomerbyid`, {
        clientKey,
        userID: sessionStorage.getItem("user_id"),
      })
      .then((res) => {
        if (res.data) {
          setMyAccount(res.data.data);
        }
      });
  };
  useEffect(() => {
    getMyAccountDetails();
  }, []);

  //change email handler
  const submitEmailHandler = (e) => {
    e.preventDefault();
    if (!validateEmail("useremail", "err_email")) {
      document.getElementById("useremail").focus();
    } else {
      axios
        .post(`${BASE_URL}/getemailotp`, {
          clientKey,
          emailID,
          userID: sessionStorage.getItem("user_id"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            setEmailModal(false);
            sessionStorage.setItem("newEmail", emailID);
            setEmailOtpModal(true);
            setEmailID("");
          } else {
            setEmailModal(true);
            setEmailID("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //verify email change otp
  const emailOtpVerifyHandler = (e) => {
    e.preventDefault();
    if (!checkForBlank("emailotp", "err_emailOtp")) {
      document.getElementById("emailotp").focus();
    } else {
      axios
        .post(`${BASE_URL}/changeemail`, {
          clientKey,
          emailID: sessionStorage.getItem("newEmail"),
          emailotp,
          userID: sessionStorage.getItem("user_id"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            sessionStorage.removeItem("newEmail");
            setEmailOtpModal(false);
            setemailChangedModal(true);
            setEmailotp("");
          } else {
            setEmailOtpModal(true);
            setEmailotp("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //change mobile number handler
  const submitMobileHandler = (e) => {
    e.preventDefault();
    if (!validateMobile("usermobile", "err_mobile")) {
      document.getElementById("usermobile").focus();
    } else {
      axios
        .post(`${BASE_URL}/getmobnumotp`, {
          clientKey,
          mobnum,
          userID: sessionStorage.getItem("user_id"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            setMobileModal(false);
            setMobileOtpModal(true);
            sessionStorage.setItem("newMobile", mobnum);
            setMobnum("");
          } else {
            setMobileModal(true);
            setMobnum("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //verify mobile change otp
  const mobileOtpVerifyHandler = (e) => {
    e.preventDefault();
    if (!checkForBlank("mobileotp", "err_mobileOtp")) {
      document.getElementById("mobileotp").focus();
    }
    axios
      .post(`${BASE_URL}/changemobnum`, {
        clientKey,
        mobnum: sessionStorage.getItem("newMobile"),
        mobnumotp,
        userID: sessionStorage.getItem("user_id"),
      })
      .then((res) => {
        if (res.data.code === 200) {
          setMobileOtpModal(false);
          setMobileChangedModal(true);
          sessionStorage.removeItem("newMobile");
          setMobnumotp("");
          setMobnum("");
        } else {
          setMobileOtpModal(true);
          setMobnumotp("");
          setMobnum("");
        }
      })
      .catch((e) => console.log(e.message));
  };
  const [oldPwdErr, setOldPwdErr] = useState("");
  // password change submit  handler
  const changePasswordHandler = async (e) => {
    let errFlag = 0;
    let oldPwd = document.getElementById("oldpwd");
    let newPwd = document.getElementById("newpwd");
    let confPwd = document.getElementById("cnewpwd");
    e.preventDefault();
    if (!checkForBlank("oldpwd", "err_oldpwd")) {
      if (errFlag === 0) {
        oldPwd.focus();
      }
      errFlag = 1;
    }
    if (!checkForBlank("newpwd", "err_newpwd")) {
      if (errFlag === 0) {
        newPwd.focus();
      }
      errFlag = 1;
    }
    if (!checkForBlank("cnewpwd", "err_cnewpwd")) {
      if (errFlag === 0) {
        confPwd.focus();
      }
      errFlag = 1;
    }

    if (errFlag === 0) {
      axios
        .post(`${BASE_URL}/changepassword`, {
          clientKey,
          oldpwd,
          newpwd,
          cnewpwd,
          userID: sessionStorage.getItem("user_id"),
        })

        .then((res) => {
          if (res.data.code === 200) {
            setPasswordModal(false);
            handleShowPasswordChanged(true);
            setOldpwd("");
            setNewpwd("");
            setCnewpwd("");
          } else {
            setOldPwdErr(res.data.message);
            setPasswordModal(true);
            setOldpwd("");
            setNewpwd("");
            setCnewpwd("");
          }
        })
        .catch((e) => console.log(e.message));
    }
  };

  //help click handler
  const helpClickHandler = () => {
    navigate("/help");
  };

  return (
    <div className="container  allpages p-5">
      <div className="row mb-5 myacount">
        <div className="col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
        <div className=" col-xl-6 col-lg-6 col-md-8 col-sm-10 card p-5 my_acount">
          <div className=" row mb-3">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <FontAwesomeIcon icon={faCircleUser} className="user_icon" />
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <p>{myAccount.customer_name}</p>
              <p>{myAccount.customer_mobile}</p>
            </div>
          </div>
          <hr />
          <div className="profile_option" onClick={handleShowEmail}>
            {changedLang.changeEmail}
          </div>
          <div className="profile_option" onClick={handleShowMobile}>
            {changedLang.changeMobile}
          </div>
          <div className="profile_option" onClick={handleShowPassword}>
            {changedLang.changePassword}
          </div>
          <div className="profile_option" onClick={helpClickHandler}>
            {changedLang.help}
          </div>
        </div>
        <div className="col-4 col-xl-3 col-lg-3 col-md-2 col-sm-1"></div>
      </div>

      {/* email change modal */}
      <Modal show={emailModal} onHide={handleCloseEmail} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${selectedLanguage.flag === "ar" ? "ar_cross" : "cross"}`}
            onClick={handleCloseEmail}
          />
        </div>
        <Modal.Body>
          <Form className="p-3" onSubmit={submitEmailHandler}>
            <Form.Group>
              <Form.Label>
                <h4 className="sub-heading pb-3 text-color">{changedLang.changeEmail}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Email"
                  aria-label="Useremail"
                  aria-describedby="basic-addon1"
                  name="emailid"
                  id="useremail"
                  value={emailID}
                  onChange={(e) => setEmailID(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_email">
                Enter valid email
              </div>
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              Get OTP
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* email change otp validate modal */}
      <Modal show={emailOtpModal} onHide={handleCloseEmailOtp} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${selectedLanguage.flag === "ar" ? "ar_cross" : "cross"}`}
            onClick={handleCloseEmailOtp}
          />
        </div>
        <Modal.Body>
          <Form className="p-3" onClick={emailOtpVerifyHandler}>
            <Form.Group>
              <Form.Label>
                <h4 className="sub-heading pb-3 text-color">{changedLang.changeEmail}</h4>
              </Form.Label>

              <p>OTP is sent to Your email id</p>

              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Otp"
                  aria-label="UserOtp"
                  aria-describedby="basic-addon1"
                  name="emailotp"
                  id="emailotp"
                  type="number"
                  value={emailotp}
                  onChange={(e) => setEmailotp(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_emailOtp">
                Enter valid OTP
              </div>
            </Form.Group>
            <button
              type="submit"
              className="btn btn-success w-100"
              onClick={handleCloseEmailOtp}
            >
              Verify OTP
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* email changed alert modal */}
      <Modal show={emailChangedModal} onHide={handleShowEmailChanged}>
        <div>
          <Modal.Header>
            <h4 className="sub-heading text-color">Alert.....!</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Your Email has been changed successfully...!</p>

            <button className="btn btn-success" onClick={handleCloseEmailChanged}>
              Okay
            </button>
          </Modal.Body>
        </div>
      </Modal>

      {/* mobile change modal */}
      <Modal show={mobileModal} onHide={handleCloseMobile} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${selectedLanguage.flag === "ar" ? "ar_cross" : "cross"}`}
            onClick={handleCloseMobile}
          />
        </div>
        <Modal.Body>
          <Form className="p-3" onClick={submitMobileHandler}>
            <Form.Group>
              <Form.Label>
                <h4 className="sub-heading pb-3 text-color">{changedLang.changePassword}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Mobile"
                  aria-label="userMobile"
                  aria-describedby="basic-addon1"
                  name="mobile"
                  type="number"
                  id="usermobile"
                  value={mobnum}
                  onChange={(e) => setMobnum(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_mobile">
                Enter valid number
              </div>
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              Get OTP
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* mobile change otp validate modal */}
      <Modal show={mobileOtpModal} onHide={handleCloseMobileOtp} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${selectedLanguage.flag === "ar" ? "ar_cross" : "cross"}`}
            onClick={handleCloseMobileOtp}
          />
        </div>
        <Modal.Body>
          <Form className="p-3" onClick={mobileOtpVerifyHandler}>
            <Form.Group>
              <Form.Label>
                <h4 className="sub-heading pb-3 text-color">{changedLang.changeMobile}</h4>
              </Form.Label>

              <p>OTP is sent to Your mobile number</p>

              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Otp"
                  aria-label="UserOtp"
                  aria-describedby="basic-addon1"
                  id="mobileotp"
                  type="number"
                  value={mobnumotp}
                  onChange={(e) => setMobnumotp(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_mobileOtp">
                Enter valid OTP
              </div>
            </Form.Group>
            <button type="submit" className="btn btn-success w-100">
              Verify OTP
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* mobile changed alert modal */}
      <Modal show={mobileChangedModal} onHide={handleShowMobileChanged}>
        <div>
          <Modal.Header>
            <h4 className="sub-heading text-color">Alert.....!</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Your Mobile number has been changed successfully...!</p>

            <button
              className="btn btn-success"
              onClick={handleCloseMobileChanged}
            >
              Okay
            </button>
          </Modal.Body>
        </div>
      </Modal>

      {/* password change modal */}
      <Modal show={passwordModal} onHide={handleShowPassword} centered>
        <div>
          <FontAwesomeIcon
            icon={faXmark}
            className={`${selectedLanguage.flag === "ar" ? "ar_cross" : "cross"}`}
            onClick={handleClosePassword}
          />
        </div>
        <Modal.Body>
          <Form onSubmit={changePasswordHandler} className="p-3">
            <Form.Group>
              <Form.Label>
                <h4 className="sub-heading pb-3 text-color">{changedLang.changePassword}</h4>
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Old password"
                  aria-label="userPassword"
                  aria-describedby="basic-addon1"
                  name="oldpwd"
                  id="oldpwd"
                  type="password"
                  value={oldpwd}
                  onChange={(e) => setOldpwd(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_oldpwd">
                Enter old password
              </div>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="New password"
                  aria-label="userNewPassword"
                  aria-describedby="basic-addon1"
                  type="password"
                  name="newpwd"
                  id="newpwd"
                  value={newpwd}
                  onChange={(e) => setNewpwd(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_newpwd">
                Enter new password
              </div>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Confirm New Password"
                  aria-label="userConfirmNewPassword"
                  aria-describedby="basic-addon1"
                  type="password"
                  id="cnewpwd"
                  name="cnewpwd"
                  value={cnewpwd}
                  onChange={(e) => setCnewpwd(e.target.value)}
                />
              </InputGroup>
              <div className="err_msg text-danger" id="err_cnewpwd">
                Confirm new password
              </div>
              {oldPwdErr && <span className="text-danger">{oldPwdErr}</span>}
            </Form.Group>
            <button
              className="btn btn-success w-100"
              type="submit"
              variant="primary"
              onClick={handleCloseMobile}
            >
              {changedLang.changePassword}
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* password changed alert modal */}
      <Modal show={passworChangedModal} onHide={handleShowPasswordChanged}>
        <div>
          <Modal.Header>
            <h4 className="sub-heading text-color">Alert.....!</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Your Password has been changed successfully...!</p>

            <button
              className="btn btn-success"
              onClick={handleClosePasswordChanged}
            >
              Okay
            </button>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default MyAccount;
